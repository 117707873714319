var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "stageline-side-by-side-viewer",
      class: { "read-only": _vm.readOnly },
    },
    [
      !_vm.readOnly
        ? _c(
            "div",
            { staticClass: "resource-fields mr-2" },
            [
              _c("document-progress-bar"),
              _vm.pdfViewerLoaded
                ? [
                    _c("pdf-schema-form", {
                      on: {
                        previousSlide: function ($event) {
                          return _vm.$emit("previous-slide")
                        },
                        previousGroup: _vm.previousGroup,
                        nextGroup: _vm.nextGroup,
                        completed: function ($event) {
                          return _vm.$emit("completed")
                        },
                        "show-contact-modal": function ($event) {
                          return _vm.$emit("show-contact-modal")
                        },
                      },
                    }),
                    _vm.savingData
                      ? _c("ct-auto-save", { attrs: { size: "75px" } })
                      : _vm._e(),
                  ]
                : _c("ct-centered-spinner"),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "stageline-pdf-viewer-container" },
        [
          _c("stageline-pdf-viewer", {
            ref: "pdf-viewer",
            attrs: {
              "read-only": _vm.readOnly,
              "show-pagination": _vm.showPagination,
            },
            on: {
              loaded: function ($event) {
                _vm.pdfViewerLoaded = true
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }